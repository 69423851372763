<template>
  <component class="leaf" :is="tag">
    <slot/>
  </component>
</template>

<script>
export default {
  name: 'Leaf',
  props: {
    tag: {
      type: String,
      default: 'div'
    }
  }
}
</script>

<style lang="scss">
$leafcomponent-margin: 0 0 $component-gap !default;
$leafcomponent-padding-y: $component-padding-y !default;
$leafcomponent-padding-x: $component-padding-x !default;
$leafcomponent-bg: $white !default;
$leafcomponent-border-radius: $leaf-border-radius-lg !default;

$leafcomponent-img-margin: $component-gap ($component-padding-x * -1);
$leafcomponent-img-width: calc(100% + #{$component-padding-x * 2});

.leaf {
  margin: $leafcomponent-margin;
  padding: $leafcomponent-padding-y $leafcomponent-padding-x;
  @include make-leaf($leafcomponent-border-radius);
  background-color: $leafcomponent-bg;

  .img-stretch {
    display: block;
    margin: $leafcomponent-img-margin;
    width: $leafcomponent-img-width;
    max-width: none;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .breadcrumbnavigation + & {
    @include reset-leaf('top');
  }
}
</style>
